const featuredSwiperItems = new Swiper('#recommend_article .swiper-container',
    {
        loop: false,
        slidesPerView: 'auto',
        spaceBetween: 35,
        slidesPerView: 1,
        navigation: {
            nextEl: '.button_next',
            prevEl: '.button_prev',
        },
        breakpoints: {
            640: {
                slidesPerView: 3,
                navigation: {
                    nextEl: '.button_next',
                    prevEl: '.button_prev',
                },
            }
        }
    });
